/* Remove the existing font import */
/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: unset;
}

::-webkit-scrollbar {
  display: none !important;
}
@keyframes expand {
  0% {
    width: 0;
  }
  100% {
    width: 50%;
  }
}
@keyframes expandsm {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.animate-expandsm {
  animation: expand 0.7s forwards;
}
.animate-expand {
  animation: expand 0.7s forwards;
}

@keyframes dote {
  0% {
    width: 0;
  }
  100% {
    width: 1%;
  }
}


.animate-dote {
  animation: dote 0.7s forwards;

}
.CityCardlisted {
  display: none;
}

.CityCard:hover .CityCardlisted {
  display: block;
 
}
.CityCard:hover .cardBorderTOp {
  border-top: solid 2px white;
}

.personCard{
   width: 'calc(15rem + 60px)';
    height: 'calc(15rem + 60px)'
}

@media only screen and (max-width: 600px) {
  .personCard{
    width: 'calc(12rem + 40px)';
     height: 'calc(12rem + 40px)'
 }
}



.custom-next-arrow,
.custom-prev-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.custom-next-arrow {
  right: -32px; 
}

.custom-prev-arrow {
  left: -32px; 
}

.tab-header {
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.tab-header-active {
  background-color: #065279;
  color: white;
  border-radius: 8px;
}

.tab-header:hover {
  transform: scale(1.1);
}

.tab-content {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tab-content-active {
  opacity: 1;
}

.gm-ui-hover-effect {
  display: none !important;
}
.gm-style-iw-ch {
  display: none;
  
}
.gm-style .gm-style-iw-c{
  background-color: rgb(249 168 37 / var(--tw-bg-opacity));
  box-shadow: none;
}
.gm-style .gm-style-iw-tc::after {
  background-color: rgb(249 168 37 );
}


@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slide-up {
  animation: slide-up 0.3s ease-out forwards;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* styles.css */
.border-bottom-transition {
  position: relative;
  display: inline-block; 
}

.border-bottom-transition::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px; 
  background-color: black;
  transform: scaleX(0); 
  transform-origin: bottom left; 
  transition: transform 0.3s ease;
  bottom: 0;
}

@media screen and (max-width: 600px) {
  .border-bottom-transition::after {
    bottom: -9px; 
  }
}


.border-bottom-transition.active::after {
  transform: scaleX(1); 
}

.border-bottom-transition {
  transition: color 0.3s ease; 
}

@keyframes bgTransition {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.bg-transition {
  background: linear-gradient(90deg, #065279, #00aaff);
  background-size: 200% 200%;
  transition: background-color 0.1s ease;
}

.bg-transition-hover:hover {
  animation: bgTransition 0.5s ease forwards;
}
.wrapper {
  width: 300px;
  padding: 0px 0px 30px;
}

.price-input {
  width: 100%;
  display: flex;
  margin: 0px 0 5px;
}
.price-input .field {
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
}
.field input {
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 19px;
  margin-left: 12px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #999;
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.price-input .separator {
  width: 130px;
  display: flex;
  font-size: 19px;
  align-items: center;
  justify-content: center;
  
}
.bar {
  height: 5px;
  position: relative;
  background: #ddd;
  border-radius: 5px;
}
.bar .progress {
  height: 100%;
  position: absolute;
  border-radius: 5px;
  background: #065279;
  transition: left 0.3s, right 0.3s; 
}

.range-input {
  position: relative;
}
.range-input input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -5px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
input[type="range"]::-webkit-slider-thumb {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: #065279;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}
input[type="range"]::-moz-range-thumb {
  height: 17px;
  width: 17px;
  border: none;
  border-radius: 50%;
  background: #065279;
  pointer-events: auto;
  -moz-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}


/* chat box */
:root {
  --yellow: #f5c32c;
  --orange: #fca61f;
  --black: #242d49;
  --gray: rgba(36, 45, 73, 0.65);
  --profileShadow: 0px 4px 17px 2px rgba(0, 0, 0, 0.25);
  --hrColor: #cfcdcd;
  --cardColor: rgba(255, 255, 255, 0.64);
  --buttonBg: linear-gradient(98.63deg, #f9a225 0%, #f95f35 100%);
  --inputColor: rgba(40, 52, 62, 0.07);
  --photo: #4CB256;
  --video: #4A4EB7;
  --location: #EF5757;
--shedule: #E1AE4A;
}

.App {
  overflow: hidden;
  color: var(--black);
  background: #f3f3f3;
  padding: 1rem 1rem;
}

/* blur */
.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #a6ddf0;
  filter: blur(72px);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: var(--buttonBg);
  transition: all 100ms ease-out;
}

.button:hover {
  color: var(--orange);
  border: 2px solid var(--orange);
  cursor: pointer;
  background: transparent;
}

.button:disabled{
  background: silver;
  pointer-events: none;
}


::-webkit-scrollbar {
  display: none;
}



/* Form Styling */
.infoForm{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.infoInput{
  border: none;
  outline: none;
  background-color: var(--inputColor);
  border-radius: 8px;
  padding: 20px;
  flex:1;
}

.infoForm>div{
  display: flex;
  gap: 1rem;
  height: 2rem;
  width: 90%;
}

.infoButton{
  width: 6rem;
  height: 2rem;
  align-self: flex-end;
}
/* chat box end */